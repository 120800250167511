<template>
  <div>
    <!--begin::Employees-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label" v-if="video_id">Edit Training video</h3>
          <h3 class="card-label" v-else>Add Training video</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <button
                type="button"
                class="ma-2 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span class="v-btn__content" @click="redirectToTrainingVideos()">
                  Back
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example mb-10">
          <v-form ref="training_video_form" v-model="valid" lazy-validation>
            <v-row no-gutters>
              <v-text-field
                cols="12"
                sm="12"
                v-model="video.name"
                :rules="nameRules"
                label="Video Name"
                required
                outlined
                dense
                class="pr-2"
              ></v-text-field>
            </v-row>

            <v-row no-gutters>
              <v-file-input
                label="Select Video"
                :rules="video_id ? [] : dynamic_rule"
                outlined
                v-model="video.video_file"
                accept="video/mp4,video/x-m4v,video/*"
                show-size
                counter
                dense
              ></v-file-input>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="12">
                <v-textarea
                  v-model="video.description"
                  label="Description"
                  outlined
                  dense
                  :rules="descriptionRules"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row class="card-footer p-0" no-gutters>
              <v-btn
                v-if="!video_id"
                :disabled="!valid"
                color="success"
                id="contact_us_submit"
                class="mr-4 mt-5"
                @click="validate"
              >
              <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                Submit
              </v-btn>
              <v-btn
                v-else
                :disabled="!valid"
                color="success"
                id="contact_us_submit"
                class="mr-4 mt-5"
                @click="update"
              >
              <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                Update
              </v-btn>
            </v-row>
          </v-form>
        </div>
        <!-- </form> -->
      </div>
    </div>
    <!--end::Employees-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import EventBus from "@/core/services/store/event-bus";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { STORE_COMMITTEE, UPDATE_COMMITTEE } from "@/core/services/store/committee.module";
import Swal from "sweetalert2";

export default {
  name: "trainingvideoForm",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      video: {
        name: "",
        description: "",
        video_file: []
      },
      video_id: this.$route.params.id,
      selectedOptions: [],
      options: [],
      descriptionRules: [v => !!v || "Description is required"],
      nameRules: [
        v => !!v || "Training Video Name is required",
        v =>
          (v && v.length <= 25) || "Training Name must be less than 25 characters"
      ],
      fileRules: [
        value =>
          !value ||
          value.size < 11000000 ||
          "File size should be less than 11 MB!"
      ],
      valid: true,
      disable_btn: false
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    let context = this;
    // set the tab from previous
    context.$store.dispatch(SET_BREADCRUMB, [{ title: "Training Video" }]);
    
    if (this.$route.params.id) {
      context.edit_form = true;
      axios({
        method: "get",
        url: "training-video/" + this.$route.params.id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.training_video) {
            context.video = result.data.training_video;
            context.video.video_file = [];
          } else {
            context.$router.go(-1); //go back to list
            context.disable_btn = false;
            Swal.fire("Error", "Training video not found", "error");
          }
        },
        function(error) {
          context.disable_btn = false;
          Swal.fire("Error", "Training video not found.", "error");
          context.$router.push({ name: "trainingvideoList" });
        }
      );
    }
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    redirectToTrainingVideos() {
      let context = this;
      context.$router.go(-1); //go back to list
    },
    validate() {
      let context = this;
      if (this.$refs.training_video_form.validate()) {
        context.disable_btn = true;
        this.submitTrainingVideo();
      } else {
        this.snackbar = true;
      }
    },
    update() {
      let context = this;
      if (this.$refs.training_video_form.validate()) {
        context.disable_btn = true;
        this.updateTrainingVideo();
      } else {
        this.snackbar = true;
      }
    },
    updateTrainingVideo() {
      let context = this;

      var formData = new FormData();
      formData.append("name", this.video.name);
      formData.append("video", this.video.video_file);
      formData.append("description", this.video.description);

      axios.post(process.env.VUE_APP_ACBACK_URL +
        "training-video/" + context.video_id, formData).then(
        function(result) {
          if(result.data.status) {
            context.$router.go(-1);
            Swal.fire("Success", result.data.message, "success");
          } else {
            Swal.fire("Warning", result.data.message, "error");
          }
        },
        function(error) {
          Swal.fire("Error", "Training video not found.", "error");
          context.$router.push({ name: "trainingvideoList" });
        }
      );
    },
    submitTrainingVideo() {
      var context = this;
      var formData = new FormData();
      formData.append("video", this.video.video_file);
      formData.append("name", this.video.name);
      formData.append("description", this.video.description);
      axios.post(process.env.VUE_APP_ACBACK_URL + "training-video",
        formData).then(
        function(result) {
          context.$router.go(-1);
          Swal.fire("Success", result.data.message, "success");
        },
        function() {
          Swal.fire("Error", "Error in save Training videos", "error");
        }
      );
    }
  }
};
</script>
